import React from 'react'
import {Link} from 'gatsby'
import logo from '../images/BayviewImage-Website.png'

const NavLink = ({href, title}) => (
  <Link className="w-50 w-auto-ns f6 f4-l link bg-animate white hover-bg-light-blue hover-black dib pa3 ph4-l"
        activeClassName="underline"
        to={href}
  >{title}
  </Link>
)
const Header = ({ siteTitle }) => (
  <div>
    <header className="">
      <div className="pv3 flex items-center justify-center flex-column">
        <Link to="/#post-content" className="">
          <h2 className="ma0 pa0 mb1 f1-ns f3 tc light-blue">
            Bayview Property Management
          </h2>
        </Link>
        <Link to="/#post-content" className="">
          <img alt="Bayview Property Management LTD" className="db ma0" src={logo}/>
        </Link>
        <div className="tc b f4 pv2">Phone us on <a href="tel:09-444-1138">09-444-1138</a> to discuss your rental property management needs</div>
        <Link className="dark-blue underline" to="/find-property#post-content"><strong>Looking for a property to rent?</strong> Click Here</Link>
      </div>
      <div className="tc f4 bg-regal-blue white pt2">How can we help you?</div>
      <nav className="b tc center bg-regal-blue flex flex-wrap justify-center">
        <NavLink href="/find-tenants#post-content" title="Find tenants"/>
        <NavLink href="/property-management#post-content" title="Rental property management"/>
        <NavLink href="/property-maintenance#post-content" title="Property maintenance"/>
        <NavLink href="/manuka-cove#post-content" title="Manuka Cove"/>
      </nav>

    </header>
  </div>
)

export default Header;
