import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"
import Header from '../components/header'
import './layout.css'

export default ({ children }) => (
    <StaticQuery
      query={graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
      `}
      render={data=> (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                  { name: 'description', content: data.site.siteMetadata.description },
                  { name: 'keywords', content: data.site.siteMetadata.keywords },
              ]}
              >
              <link rel="stylesheet" type="text/css" href="https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css"/>
              <script>
                {`
                var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                (function(){
                  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                  s1.async=true;
                  s1.src='https://embed.tawk.to/5c0de1f567f72462651fc5a3/default';
                  s1.charset='UTF-8';
                  s1.setAttribute('crossorigin','*');
                  s0.parentNode.insertBefore(s1,s0);
                })();
                `}
              </script>
            </Helmet>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div className="measure-wide center ph2">
              {children}
            </div>
            <footer className="ph3 ph4-ns pv5 bt b--black-10 black-70 tc">
              <a href="mailto:info@propmanage.co.nz" className="link b f3 f2-ns dim black-70 lh-solid">info@propmanage.co.nz</a>
              <p className="f6 db b ttu lh-solid mt2">© 2020 Bayview Property Management Limited</p>
              <div className="mt5">
                <a href="/privacy/"  title="Privacy" className="f6 dib ml2 mid-gray dim">Privacy</a>
              </div>
            </footer>
          </>
      )} />
)
