import React from 'react'
import { navigateTo } from "gatsby";

export const Input = ({type, name, required, onChange}) => (
    <div className="mv3">
      <label className="db fw6 lh-copy f6" htmlFor={name}>{name}</label>
      <input className="b pa2 input-reset ba bg-white hover-bg-regal-blue hover-white w-100" 
             type={type} name={name} required onChange={onChange}/>
    </div>
)
Input.defaultProps = {
    required: true,
}


function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
            .then(() => navigateTo(form.getAttribute("action")))
            .catch(error => alert(error));
    };
    render() {
        return (
            <form method="post"
                  data-netlify="true"
                  data-netlify-honeypot="antib"
                  action="/thanks-for-contacting/"
                  name={this.props.name}
                  onSubmit={this.handleSubmit}
                  >
              <input type="hidden" name="form-name" value={this.props.name}/>
              <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                <p className="dn">
                  <label>Don’t fill this out if you're human: <input name="antib" onChange={this.handleChange}/></label>
                </p>
                <Input type="text" name="Name" onChange={this.handleChange}/>
                <Input type="email" name="Email" onChange={this.handleChange}/>
                <Input type="tel" name="Phone number" onChange={this.handleChange}/>
                <Input type="text" name="Address" onChange={this.handleChange}/>
                { this.props.children }
                <div>
                  <label htmlFor="comment" className="f6 b db mb2">Message <span className="normal black-60">(optional)</span></label>
                  <textarea id="comment" name="comment" className="db border-box w-100 measure ba b--black pa2 br2 mb2 hover-bg-regal-blue hover-white" 
                            aria-describedby="comment-desc"
                            onChange={this.handleChange}>
                  </textarea>
                </div>

              </fieldset>
              <div className="">
                <input className="b ph3 pv2 input-reset ba b--black bg-white grow pointer f6 dib" type="submit" value="Send message" />
              </div>
            </form> 
        )
    }
    
}
export class WaitingListForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
            .then(() => navigateTo(form.getAttribute("action")))
            .catch(error => alert(error));
    };
    render() {
        return (
            <form method="post"
                  data-netlify="true"
                  data-netlify-honeypot="antib"
                  action="/added-to-waiting-list/"
                  name={this.props.name}
                  onSubmit={this.handleSubmit}
                  >
              <input type="hidden" name="form-name" value={this.props.name}/>
              <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                <p className="dn">
                  <label>Don’t fill this out if you're human: <input name="antib" onChange={this.handleChange}/></label>
                </p>
                <Input type="text" name="Name" onChange={this.handleChange}/>
                <Input type="email" name="Email" onChange={this.handleChange}/>
                <Input type="tel" name="Phone number" onChange={this.handleChange}/>
                <Input type="text" name="How many bedrooms are you looking for?" onChange={this.handleChange}/>
                <Input type="text" name="In what area are you looking for a property?" onChange={this.handleChange}/>
                { this.props.children }
                <div>
                  <label htmlFor="comment" className="f6 b db mb2">Message <span className="normal black-60">(optional)</span></label>
                  <textarea id="comment" name="comment" className="db border-box w-100 measure ba b--black pa2 br2 mb2 hover-bg-regal-blue hover-white"
                            aria-describedby="comment-desc"
                            onChange={this.handleChange}>
                  </textarea>
                </div>

              </fieldset>
              <div className="">
                <input className="b ph3 pv2 input-reset ba b--black bg-white grow pointer f6 dib" type="submit" value="Send message" />
              </div>
            </form>
        )
    }

}

const PrettyForm = ({headline, instructions, name, children}) => (
    <div className="measure center ba pa2 bg-light-blue" >
      <h3 className="ma0">{headline}</h3>
      <div className="i">{instructions}</div>
      <Form name={name}>
        { children }
      </Form>
    </div>
)
PrettyForm.defaultProps = {
    headline: "Contact us to discuss your requirements",
    instructions: "Enter your details below, and we will call you back within the next 24 hours to discuss your rental property management needs.",
    name: "contact-us"
}
export const PrettyWaitingListForm = ({headline, instructions, name, children}) => (
    <div className="measure center ba pa2 bg-light-blue" >
      <h3 className="ma0">{headline}</h3>
      <div className="i">{instructions}</div>
      <WaitingListForm name={name}>
        { children }
      </WaitingListForm>
    </div>
)
export default PrettyForm;
