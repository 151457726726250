import React from 'react'
import { withPrefix } from 'gatsby'

export default ({src, children}) => {
    var thumb = src.split("/");
    thumb.splice(-1, 0, "thumbs");
    thumb = thumb.join("/");
    return (<a href={withPrefix(src)} target="_blank">
         <img src={withPrefix(thumb)}/></a>)
}
